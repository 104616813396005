import React from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber'

interface FrameProps {
  translation: [number, number, number];
  quaternion: [number, number, number, number];
  frameColor: string
}


export const createArrow = (start: THREE.Vector3, end: THREE.Vector3, color: number) => {
  const path = new THREE.LineCurve3(start, end);
  const geometry = new THREE.TubeGeometry(path, 10, 0.01, 8, false);
  const material = new THREE.MeshBasicMaterial({ color });
  return <mesh geometry={geometry} material={material} />;
};


export const Frame: React.FC<FrameProps> = ({ translation, quaternion, frameColor }) => {
  const frameRef = React.useRef<THREE.Mesh>(null);
  // const arrowRef = useRef<THREE.LineSegments>(null);
  // var translation = [0, 0, 0]
  // var quaternion = [0, 0, 0, 1]

  useFrame(() => {
    if (frameRef.current) {
      frameRef.current.position.set(...translation);
      frameRef.current.quaternion.set(...quaternion);
    }
  });


  return (
    <mesh ref={frameRef}>
      <planeGeometry args={[0.3, 0.2]} /> {/* Adjust the size as needed */}
      <meshBasicMaterial color={new THREE.Color().setHex(Number(frameColor))} side={THREE.DoubleSide} />
      {createArrow(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0.1), 0x00ff00)}
      {/* <lineSegments ref={arrowRef} geometry={arrowGeometry} material={arrowMaterial} /> */}
    </mesh>
  );
};