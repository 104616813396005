import React, { useEffect, useState } from 'react';
// import { Select } from '@chakra-ui/react'
import axios from 'axios';
import { Input } from 'reactstrap';
import { getArSessionsId } from 'api/vps_debug';
import { ArSessions } from './VpsDebugModal';


interface DropdownProps {
    selectedValue: string| undefined;
    onChange: (value: string) => void;
}

// interface Log {
//   id: string;
//   location_name: string;
// }

// interface ApiResponse {
//     status: boolean;
//     data: Log[];
//   }

const ArSessionList: React.FC<DropdownProps> = ({ selectedValue, onChange }) => {
  const [arSessions, setArSessions] = useState<ArSessions[]>([]);

  useEffect(() => {
          getArSessionsId().then(data => {
          setArSessions(data.data)
          })
      }, [])

  return (
    <Input
        type="select"
        className="custom-select"
        style={{ backgroundColor: '#333', color: 'white' }}
        onChange={(e) => onChange(e.target.value)}
        value={selectedValue}
        defaultValue="Select an AR Session ID Logs"
    >
        {/* <option value="" disabled selected hidden>Select an AR Session ID Logs</option> */}
        {arSessions.map((log) => (

        <option key={String(log.id)} value={String(log.id)}>
          {log.location_name}: {log.id}
        </option>
      ))}
    </Input>

  );
};

export default ArSessionList;