import axios from "axios"
// import { Pipeline, Reconstruction } from "modules/ScanningData/scanningDataHook";
import { ArSessionsResponse, ArLogResponse, ArSolverLogResponse } from "modules/VpsDebug/VpsDebugModal";
import { generateBaseHeader } from "./apiHelper";
import getBackendUrl from "const";

const backendUrl = getBackendUrl()

export const getArSessionsId = async (): Promise<ArSessionsResponse> => {
    const header = await generateBaseHeader()
    const resp = await axios({
        method: "GET",
        url: backendUrl + "/vps-debugger/ar-sessions/",
        headers: {
            ...header,
        },
    })

    if (resp.status === 200) {
        return resp.data;
    }
    console.error("getArSessionsId fail");
    const error_response: ArSessionsResponse = {
        status: false,
        data: [],
    }
    return error_response;
}


export async function getArSessionLog(ar_session_id: string): Promise<ArLogResponse | undefined> {
    const header = await generateBaseHeader()
    const response = await fetch(
      `${backendUrl}/vps-debugger/get-logs/${ar_session_id}/`,
      {
        headers: {
            ...header,
        },
      }
    );
    if (response.status === 200){
        return response.json();
    }

    return 
  }


  export async function getArSessionSolverLog(ar_session_id: string): Promise<ArSolverLogResponse | undefined> {
    const header = await generateBaseHeader()
    const response = await fetch(
      `${backendUrl}/vps-debugger/get-solver-logs/${ar_session_id}/`,
      {
        headers: {
            ...header,
        },
      }
    );
    if (response.status === 200){
        return response.json();
    }

    return 
  }