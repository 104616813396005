import { VpsResult, SolverLogCood } from "./api";
import * as THREE from 'three';

export interface FrameData {
    timestamp: number;
    deviceId: string;
    quaternion: [number, number, number, number];
    translation: [number, number, number];
  }
  
export const parseFramesFile = async (url: string): Promise<FrameData[]> => {
    const response = await fetch(url);
    const text = await response.text();
  
    const frames: FrameData[] = text
      .trim()
      .split('\n')
      .filter(line => !line.startsWith('#'))
      .map(line => {
        const [timestamp, deviceId, qw, qx, qy, qz, tx, ty, tz] = line.split(',').map(value => value.trim());
        return {
          timestamp: Number(timestamp),
          deviceId,
          quaternion: [Number(qw), Number(qx), Number(qy), Number(qz)],
          translation: [Number(tx), Number(ty), Number(tz)],
        };
      });
  
    return frames;
  };

export const parseFramesLogs = (frames_logs: VpsResult[]) => {
  const frames: FrameData[] = []
  for (let frame of frames_logs){

    frames.push(
      {
        timestamp: 0,
        deviceId: "",
        quaternion: [frame.quaternion.w, frame.quaternion.x, frame.quaternion.y, frame.quaternion.z],
        translation: [frame.position.x, frame.position.y, frame.position.z]
      }
    )
  }
  return frames

}


export const parseFramesSolverLogs = (frames_logs: SolverLogCood[]) => {
  const frames: FrameData[] = []
  for (let frame of frames_logs){
    // var position = new THREE.Vector3(0, 0, 0);
    var translation = new THREE.Vector3(frame.translation.x, frame.translation.y, frame.translation.z);
    var quaternion = new THREE.Quaternion(frame.rotation.x, frame.rotation.y, frame.rotation.z, frame.rotation.w);
    const rotationMatrix = new THREE.Matrix4();
    rotationMatrix.makeRotationFromQuaternion(quaternion);
    rotationMatrix.transpose();
    rotationMatrix.multiplyScalar(-1);
    var vector4 = new THREE.Vector4(translation.x, translation.y, translation.z, 1);
    vector4.applyMatrix4(rotationMatrix);
    var position = new THREE.Vector3(vector4.x, vector4.y, vector4.z);

    frames.push(
      {
        timestamp: 0,
        deviceId: "",
        quaternion: [frame.rotation.w, frame.rotation.x, frame.rotation.y, frame.rotation.z],
        translation: [position.x, position.y, position.z]
      }
    )
  }
  return frames

}