"use client"

import React, { useEffect, useState, Suspense } from 'react';
import ArSessionList from './ArSessionIdList';
import { getArSessionLog } from 'api/vps_debug';
import { VpsLog } from './VpsDebugModal';

import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js';
import * as THREE from 'three';
import { OrbitControls } from '@react-three/drei'
import { createArrow, Frame } from './drawFrame';
import { parseFramesLogs, FrameData } from './parseFrames';

const VpsLogs: React.FC = () => {
    const [selectedLog, setSelectedLog] = useState<string | undefined>(undefined);
    const [arSessionLog, setArSessionLog] = useState<VpsLog | undefined>(undefined);
    const [frames, setFrames] = useState<FrameData[]>([]);

    const handleDropdownChange = async (value: string) => {
        setSelectedLog(value);
        console.log('Selectnm ed Log:', value); // You can use this value as needed
        console.log(selectedLog)
        const ar_session_log = await getArSessionLog(value)
        if (typeof ar_session_log !== 'undefined' && ar_session_log !== null) {
            setArSessionLog(ar_session_log.data);
            console.log(arSessionLog)
            const frames = parseFramesLogs(ar_session_log.data.vps_result)
            setFrames(frames)
        }
        
      };

    return (
    <>
    <ArSessionList selectedValue={selectedLog} onChange={handleDropdownChange} />
    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#333' }}>
      <div style={{ width: '20px', height: '20px', backgroundColor: 'red' }}></div>
      <p style={{ marginLeft: '10px', marginTop: '10px', color: 'white' }}>VPS Frame</p>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#333' }}>
      {arSessionLog ? <h2 style={{color: 'white'}}>{arSessionLog.location_name}</h2> : ""}
    </div>
    <Canvas
            camera={{ fov: 75, near: 0.1, far: 1000, position: [10, 5, 40] }}
            // style={{ borderRadius: "4px" }}
            onPointerMissed={() => { }}
            gl={{ antialias: true, toneMapping: THREE.NoToneMapping, localClippingEnabled: true }}
    
          >
            <color attach="background" args={['#242424']} />
            <ambientLight intensity={3} />
            <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
            <pointLight position={[-10, -10, -10]} />
            <OrbitControls
              makeDefault
              enableDamping={false}
            />
            <Suspense fallback={null}>
              {
                arSessionLog ? <PointCloud data_url={arSessionLog.data_url}/>: <></>
    
              }
              {
              arSessionLog ? (frames.map((frame, index) => (
                <Frame 
                  key={index}
                  translation={frame.translation} 
                  quaternion={frame.quaternion} 
                  frameColor='0xff0000'
                />
              ))) : <></>
              }
            </Suspense>
    
          </Canvas>
    </>

    )
}

const VpsDebug: React.FC = () => {
    return (
      <div style={{ width: '100vw', height: '100vh', marginTop: "70px" }}>
        {/* <h1>Three.js with PLY in Next.js</h1> */}
        <VpsLogs />
        {/* <Container fluid className="h-1000 g-0" > */}
        {/* <ThreeScene /> */}
        {/* </Container> */}
      </div>
    );
  };

interface PointCloudProps {
    data_url: string;
  }

const PointCloud: React.FC<PointCloudProps> = ({data_url}) => {
    const result = useLoader(PLYLoader, data_url)
    const material = new THREE.PointsMaterial({
      vertexColors: true,
      size: 0.01,
    });
    const point = new THREE.Points(result, material);
    return <primitive object={point} />
  };

export default VpsDebug